const Theme = {
	colors: {
		"primary": "#1E1A1EFF",
		"onPrimary": "#B02020",
		"primaryContainer": "#E5D3AB",
		"onPrimaryContainer": "#791616",
		"onPrimaryContainerSecondary": "#DCF2F0",

		"secondary": "#12335C",
		"onSecondary": "#E5D3AB",
		"secondaryContainer": "#004E60FF",
		"onSecondaryContainer": "#E5D3AB",

		"tertiary": "#791616",
		"onTertiary": "#E5D3AB",
		"tertiaryContainer": "#4B4900FF",
		"onTertiaryContainer": "#EDE76DFF",

		"error": "#FFB4ABFF",
		"onError": "#690005FF",
		"errorContainer": "#93000AFF",
		"onErrorContainer": "#FFB4ABFF",
		"background": "#1E1A1EFF",
		"onBackground": "#E8E0E4FF",
		"surface": "#1E1A1EFF",
		"onSurface": "#E8E0E4FF",
		"surfaceVariant": "#2B2C2D",
		"onSurfaceVariant": "#CFC3CDFF",
		"outline": "#7A7A7A",
		"outlineVariant": "#4D444CFF",
		"shadow": "#000000",
		"scrim": "#000000",
		"inverseSurface": "#E8E0E4FF",
		"inverseOnSurface": "#332F32FF",
		"inversePrimary": "#2A7C6F",
		"elevation": {
			"level0": "#FDFDFE",
			"level1": "#41A98F",
			"level2": "#2A7C6F",
			"level3": "#3A5E59",
			"level4": "#214949",
			"level5": "#051C21",
			"level6": "#021114",
		},
		"surfaceDisabled": "rgba(232, 224, 228, 0.12)",
		"onSurfaceDisabled": "rgba(232, 224, 228, 0.38)",
		"backdrop": "rgba(54, 46, 53, 0.4)",
	},
};

export default Theme;

