import React from "react";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons';
import {Provider} from "react-redux";
import store from "./globals/store";
import "./App.scss";
import 'react-modal-video/scss/modal-video.scss';
import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import ReferralProgram from "./pages/ReferralProgram";
import Privacy from "./pages/Privacy/Privacy";
import TermsAndConditions from "./pages/TermsAndConditions";
import Support from "./pages/Support/Support";
import Status from "./pages/Status";
import ReceivePayment from "./pages/ReceivePayment/ReceivePayment";
import Airdrop from "./pages/Airdrop/Airdrop";
import FAQ from "./pages/FAQ/FAQ";
import News from "./pages/News/News";
import Newsletter from "./pages/Newsletter/Newsletter";
import Login from "./pages/Login/Login";
import SignUp from "./pages/SignUp/SignUp";
import MyMoney from "./pages/MyMoney/MyMoney";
import MyAccount from "./pages/MyAccount/MyAccount";
import Logout from "./pages/Logout";
import PageLayout from "./pages/PageLayout";
import NotFound from "./Components/NotFound/NotFound";
import Currencies from "./pages/Currencies";
import AboutUs from "./pages/AboutUs";
import AppComingSoon from "./pages/AppComingSoon";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ReactURLs } from "./globals/urls";


AOS.init();
library.add(fab, faCheckSquare, faCoffee);


function App() {
    return (
        <Provider store={store}>
            {/*<ParallaxProvider>*/}
                <div className="App">
                    <PageLayout>
                        <Switch>
                            {/* Home and main page routes */}
                            <Route path={ReactURLs.home} component={Home} exact/>
                            <Route path={ReactURLs.appComingSoon} component={AppComingSoon}/>
                            <Route path={ReactURLs.aboutUs} component={AboutUs}/>
                            <Route path={ReactURLs.curencies} component={Currencies}/>
                            <Route path={ReactURLs.referralsRewards} component={ReferralProgram}/>
                            <Route path={ReactURLs.support} component={Support} exact/>
                            <Route path={ReactURLs.privacy} component={Privacy}/>
                            <Route path={ReactURLs.termsAndConditions} component={TermsAndConditions}/>
                            <Route path={ReactURLs.status} component={Status} exact/>

                            {/* Additional routes (release date: TBD) */}
                            <Route path={ReactURLs.airdrop} component={Airdrop} exact/>
                            <Route path={ReactURLs.faq} component={FAQ} exact/>
                            <Route path={ReactURLs.news} component={News} exact/>
                            <Route path={ReactURLs.newsLetter} component={Newsletter} exact/>

                            {/* Crypto Ninja application pages (release date: TBD) */}
                            <Route path={ReactURLs.receivePayment} component={ReceivePayment} exact/>
                            <Route path={ReactURLs.myMoney} component={MyMoney} exact/>
                            <Route path={ReactURLs.myAccount} component={MyAccount} exact/>

                            {/*  Login, Sign Up, Logout routes (release date: TBD) */}
                            <Route path={ReactURLs.login} component={Login} exact/>
                            <Route path={ReactURLs.signup} component={SignUp} exact/>
                            <Route path={ReactURLs.logout} component={Logout} exact/>

                            <Route component={NotFound}/>
                        </Switch>
                    </PageLayout>
                </div>
            {/*</ParallaxProvider>*/}
        </Provider>
    );
}

export default App;
