import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { updatePageTitle } from "../../reducers/page";
import "./Home.css";
import Section from "../../Components/Content/Section";
import { Parallax } from 'react-parallax';
import ModalVideo from 'react-modal-video';
import demoVideoImage from "../../assets/img/banners/TacAO_banner_1_1792x912.jpg";
import mobileAppMerchantImage1 from "../../assets/img/mobile-app/merchant-step-1-receive-payment-screen.png"
import mobileAppCustomerImage1_2 from "../../assets/img/mobile-app/customer-step-1-2-receive-payment-screen.png"
import mobileAppMerchantImage2 from "../../assets/img/mobile-app/merchant-step-2-enter-amount-screen.png"
import mobileAppMerchantImage3 from "../../assets/img/mobile-app/merchant-step-3-scan-QR-code-screen.png"
import mobileAppCustomerImage3 from "../../assets/img/mobile-app/customer-step-3-scan-QR-code-screen.png"
import mobileAppMerchantImage4 from "../../assets/img/mobile-app/merchant-step-4-payment-confirmed-screen.png"
import mobileAppCustomerImage4 from "../../assets/img/mobile-app/customer-step-4-payment-confirmed-screen.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";
import FAQ from "../../Components/Content/FAQ";
import PageLinks from "../../Components/Content/PageLinks";
import { H1, H2, SectionTitle, P, LI, OL, UL, } from "../../Components/Content/Content";
import theme from "../../globals/theme";


class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			...{className: props.className},
		};
		this.openModal = this.openModal.bind(this);
		if (this.state.page.pageTitle != Home.defaultProps.page.pageTitle) {
			props.updatePageTitle(Home.defaultProps.page.pageTitle);
		}
	}

	openModal () {
		this.setState({isOpen: true})
	}

	render() {
		const sectionDetails1 = (
			<div>
				<p>
					Accept Bitcoin and Ethereum for purchases,
					<span style={{color: '#FF6600', fontWeight: "bold"}}> get paid in Dollars </span>
					with <span style={{color: '#FF6600', fontWeight: "bold"}}>Zero fees. Same as Cash.</span>
				</p>
				<p>
					The simple, safe and secure way to accept crypto payments for your business.
				</p>
			</div>
		);
		const sectionDetails2 = (
			<div>
				<p>
					Want customers to spend Bitcoin & Ethereum at your business with no risks and no fees? We make it
					simple in 4 easy steps and you get paid in dollars, same as cash.  Boost your sales by
					accepting crypto payments.
				</p>
				<p>
					Get paid with zero fees.
				</p>
			</div>
		);
		const sectionDetails3 = (
			<div>
				<Row>
					<Col xs={1} sm={2} md={0}></Col>
					<Col sx={10} sm={8} md={12}>
						<p>
							<ul>
								<li style={{textAlign: 'left', }}>
									Free easy set up
								</li>
								<li style={{textAlign: 'left', }}>
									You don’t need crypto currency to accept payments
								</li>
								<li style={{textAlign: 'left', }}>
									Simple 4 step process
								</li>
								<li style={{textAlign: 'left', }}>
									No transaction fees, app fees or monthly fees
								</li>
							</ul>

							Our free app makes accepting cryptocurrency for your business safe, secure, and fast!
						</p>
					</Col>
					<Col xs={1} sm={2} md={0}></Col>
				</Row>

			</div>
		);
		const sectionDetails4 = (
			<div>
				<p>
					Join one of our referral or partner programs for ways to grow your business. Be part of this 1.5
					trillion dollar industry that is booming.
				</p>
				<p>
					Get paid in Dollars with Zero Fees!
				</p>
			</div>
		);
		return (
			<div>
				<H1>Welcome to Vet The Vote (V12)</H1>
				<P>Your Voice.  Your Vote.  Your Power.  Your Future.</P>

				<section>
					<SectionTitle>
						Our Mission
					</SectionTitle>

					<P>
						Vet The Vote (V12) is a decentralized system designed to protect voting integrity, strengthen our economy, and empower citizens. Our mission is to:
						<UL>
							<LI>
								Restore trust in our nation’s voting system.
							</LI>
							<LI>
								Stabilize the value of our currency.
							</LI>
							<LI>
								Safeguard the right to free trade.
							</LI>
							<LI>
								Distribute wealth more equitably among citizens.
							</LI>
						</UL>
					</P>
				</section>

				<section>
					<SectionTitle>
						Our Goals
					</SectionTitle>

					<P>
						V12 achieves these goals by:
						<UL>
							<LI>
								Eliminating the “double vote” problem in elections.
							</LI>
							<LI>
								Preventing “double spending” that leads to inflation.
							</LI>
							<LI>
								Ensuring private, unrestricted transactions for all users.
							</LI>
							<LI>
								Providing fair rewards to citizens for their roles in securing V12.
							</LI>
						</UL>
					</P>
				</section>

				<section>
					<SectionTitle>
						What V12 Does
					</SectionTitle>

					<P>
						V12 is an advanced, decentralized computer network powered by citizen-operated nodes that link voting, work, and economic stability. With V12, citizens secure our nation’s voting system while benefitting financially. This blockchain-based platform operates through six key processes:
					</P>

					<P>
						<OL>
							<LI>
								<strong>Vetting the Vote</strong>: Citizens act as Poll Watchers, validating voter citizenship, district, and ballot accuracy. V12 randomly assigns these verification tasks, rewarding participants with V12 tokens.
							</LI>
							<LI>
								<strong>Minting New Currency</strong>: V12 creates and distributes tokens only when valuable work is done, such as voting verification, managing a compute node, and participating in the economy. This controls inflation and supports economic stability.
							</LI>
							<LI>
								<strong>Secure, Private Transactions</strong>: Transactions can be public (visible to all users) or private (viewable only by sender and receiver), safeguarding user privacy.
							</LI>
							<LI>
								<strong>Transaction Fees for Funding Projects</strong>: A portion of transaction fees funds citizen-approved public projects, ensuring transparency and local accountability.
							</LI>
							<LI>
								<strong>Free Trade</strong>: V12 enables free trade between users in any location and across various formats (digital, in-person, paper), allowing seamless global transactions.
							</LI>
						</OL>
					</P>
				</section>

				<section>
					<SectionTitle>
						Legal and Economic Benefits
					</SectionTitle>

					<P>
						V12 tokens operate like Bitcoin, meaning they are legally protected and free from securities classification by the SEC. These tokens can only be earned by doing valuable work, preventing inflationary risks and aligning with U.S. legal precedents.
					</P>
				</section>

				<section>
					<SectionTitle>
						The Heart of V12 – The Citizens
					</SectionTitle>

					<P>
						V12’s success relies on citizen involvement. Rewards for operating and securing V12 protect citizens from economic downturns and technological displacement, ensuring a fair distribution of wealth and supporting consumer confidence.
					</P>
				</section>

				<section>
					<SectionTitle>
						Roles, Tasks, and Tokens
					</SectionTitle>

					<P>
						<strong>Roles</strong>
						<UL>
							<LI>
								<strong>Users</strong>: Anyone with a free V12 account.
							</LI>
							<LI>
								<strong>Citizens</strong>: Verified users who’ve met the citizenship requirements.
							</LI>
							<LI>
								<strong>Poll Watchers</strong>: Citizens randomly selected to validate voter information.
							</LI>
							<LI>
								<strong>Sellers and Buyers</strong>: Participants in the V12 Marketplace, trading with V12 tokens.
							</LI>
						</UL>
					</P>

					<P>
						<strong>Tasks</strong>
						<UL>
							<LI>
								<strong>Verify Citizenship</strong>: Validate identity per U.S. law.
							</LI>
							<LI>
								<strong>Verify Voting District</strong>: Confirm address.
							</LI>
							<LI>
								<strong>Verify Ballot</strong>: Confirm ballot accuracy on voting day.
							</LI>
							<LI>
								<strong>Sell Items</strong>: Anyone can buy or sell products or services in the V12 Marketplace.
							</LI>
						</UL>
					</P>

					<P>
						<strong>Tokens</strong>
						<UL>
							<LI>
								<strong>Citizenship Token</strong>: Issued upon citizenship verification, valid for life.
							</LI>
							<LI>
								<strong>District Token</strong>: Issued for district verification, used to vote.
							</LI>
							<LI>
								<strong>Voting Token</strong>: Used to cast ballots in the verified district.
							</LI>
							<LI>
								<strong>V12 Tokens</strong>: Rewards for completing V12 tasks, usable in the V12 Marketplace.
							</LI>
						</UL>
					</P>
				</section>

				<section>
					<SectionTitle>
						Unmatched Security
					</SectionTitle>
					<P>
						Every part of V12 is protected by quantum-resistant encryption, approved by the National Institute of Standards and Technology (NIST), ensuring robust security for the future.
					</P>
				</section>


			</div>

		);

	}
}

Home.defaultProps = {
	isOpen: false,
	page: {
		pageTitle: ""
	}
};

const styles = {
	home: {
	},
	whiteBackgroundColor: {
		backgroundColor: 'white',
		color: theme.colors.primary,
	},
	blueBackgroundColor: {
		backgroundColor: theme.colors.onPrimary,
		color: 'white',
	},
};

const mapStateToProps = (state) => {
	const { page } = state;
	return { page };
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		updatePageTitle,
	}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Home);
