import React, { Component } from "react";
import Image from "react-bootstrap/Image";


class GooglePlayStore extends Component {
	render() {
		return (
			<div style={style}>
				<Image width="128" src="/img/google-play-store.png" />
			</div>
		);
	}
}

const style = {
	margin: 0,
	padding: 0,
};

export default GooglePlayStore;
