import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {CNBlue} from "./SiteColors";
import { ExternalURLs } from "../../globals/urls";
import rumbleIcon from "../../assets/img/social_media/RumbleIcon.svg";


class SocialMediaLinks extends Component {
    constructor(props) {
        super(props);
        this.state = {...props};
    }

    render() {
        return (
            <div
                className={this.state.className}
                style={this.state.style}
            >
                <a
                    href={ExternalURLs.YouTube}
                    target="_blank"
                >
                    <FontAwesomeIcon
                        icon={["fab", "youtube"]}
                        size="lg"
                        style={{
                            ...styles.icon,
                            ...styles.youtube,
                        }}
                    />
                </a>

                <a
                    href={ExternalURLs.Rumble}
                    target="_blank"
                >
                    <img
                        src={rumbleIcon}
                        style={{
                            ...styles.icon,
                            width: 20,
                            color: 'red',
                            fill: 'red',
                        }}
                    />
                </a>

                <a
                    href={ExternalURLs.Discord}
                    target="_blank"
                >
                    <FontAwesomeIcon
                        icon={["fab", "discord"]}
                        size="lg"
                        style={{
                            ...styles.icon,
                            ...styles.discord,
                        }}
                    />
                </a>


            </div>
        );
    }
}


SocialMediaLinks.defaultProps = {
    style: {
        color: 'black',
        backgroundColor: 'white',
    },
    className: "m-0 p-0",
};

const styles = {
    icon: {
        color: CNBlue,
        marginLeft: 5,
        marginRight: 5
    },
    youtube: {
        color: 'red',
    },
    discord: {
        color: '#5865F2',
    },
};


export default SocialMediaLinks;

