import React, { Component } from "react";
import Content, { H3, P } from "../Components/Content/Content";


class TermsAndConditions extends Component {
	render() {
		return (
			<div className="px-sm-1 px-md-5 py-md-4">
				<Content>
					<H3>About Us</H3>
					<P>
						We love crypto-currency and believe it is the future of finance.  But, we saw small problem.
						Very few stores accept crypto-currency.  We built Crypto Ninja to solve that problem.
					</P>
					<P>
						Download our free app and create a new account in less than 2 minutes.
					</P>
					<P>
						Start offering your customers the choice to pay with crypto currency.
					</P>
					<P>
						Works like credit card processing without the fees.
					</P>
					<P>
						Zero fees for transactions, same as cash.
					</P>
					<P>
						We designed this app to help business owners benefit from the huge crypto-currency market.
					</P>
					<P>
						Bitcoin and Ethereum are headline news nearly every day and now, with our app, you can take
						advantage of this growing market without having to own or buy any yourself.
					</P>
					<P>
						Download our app, create a new account, and start accepting payments in USD with zero fees
						using our simple and secure 4 step process.
					</P>
				</Content>
			</div>
		);
	}
}


const styles = {};

export default TermsAndConditions;

