import React, { Component } from "react";
import { CNBlue } from "./SiteColors";
import theme from "../../globals/theme";


export const H1 = (props) => {
	const style = props?.style ? props.style : {};
	return (
		<h1
			style={{
				...styles.heading1,
				...style,
			}}
		>
			{props?.children}
		</h1>
	);
};

export const H2 = (props) => {
	const style = props?.style ? props.style : {};
	return (
		<h2
			style={{
				...styles.heading2,
				...style,
			}}
		>
			{props?.children}
		</h2>
	);
};

export const H3 = (props) => {
	const style = props?.style ? props.style : {};
	return (
		<h3
			style={{
				...styles.heading3,
				...style,
			}}
		>
			{props?.children}
		</h3>
	);
};

export const H4 = (props) => {
	const style = props?.style ? props.style : {};
	return (
		<h4 style={{
			...styles.heading4,
			...style,
		}}>
			{props?.children}
		</h4>
	);
};

export const P = (props) => {
	return (
		<p style={styles.paragraph}>
			{props?.children}
		</p>
	);
};

export const UL = (props) => {
	const style = props?.style ? props.style : {};
	return (
		<ul
			style={{
				...styles.list,
				...style,
			}}
		>
			{props?.children}
		</ul>
	);
}

export const OL = (props) => {
	const style = props?.style ? props.style : {};
	return (
		<ol
			style={{
				...styles.list,
				...style,
			}}
		>
			{props?.children}
		</ol>
	);
}

export const LI = (props) => {
	const style = props?.style ? props.style : {};
	return (
		<li
			style={{
				...styles.listItem,
				...style,
			}}
		>
			{props?.children}
		</li>
	);
}

export const Table = (props) => {
	const style = props?.style ? props.style : {};
	return (
		<table
			style={{
				...styles.table,
				...style,
			}}
		>
			{props?.children}
		</table>
	);
}

export const TableHeader = (props) => {
	const style = props?.style ? props.style : {};
	return (
		<th
			style={{
				...styles.tableHeader,
				...style,
			}}
		>
			{props?.children}
		</th>
	);
}

export const TableRow = (props) => {
	const style = props?.style ? props.style : {};
	return (
		<tr
			style={{
				...styles.tableRow,
				...style,
			}}
		>
			{props?.children}
		</tr>
	);
}

export const TableColumn = (props) => {
	const align = props?.align ? props.align : 'left';
	const valign = props?.valign ? props.valign : 'center';
	const style = props?.style ? props.style : {};
	return (
		<td
			align={align}
			valign={valign}
			style={{
				...styles.tableColumn,
				...style,
			}}
		>
			{props?.children}
		</td>
	);
}

export const TableColumnSubHeading = (props) => {
	const style = props?.style ? props.style : {};
	return (
		<div
			style={{
				...styles.tableColumnSubHeading,
				...style,
			}}
		>
			{props?.children}
		</div>
	);
}

export const SectionTitle = (props) => {
	const style = props?.style ? props.style : {};
	return (
		<div
			style={{
				...styles.sectionTitle,
				...style,
			}}
		>
			{props?.children}
		</div>
	);
}

class Content extends Component {
	render() {
		return (
			<div>
				{this.props.children}
			</div>
		);
	}
}


const styles = {
	heading1: {
		color: theme.colors.onPrimaryContainerSecondary,
		backgroundColor: theme.colors.tertiary,
		textAlign: 'left',
		paddingLeft: '10%',
		paddingRight: '10%',
		paddingBottom: '7px',
		paddingTop: '20px',
		marginBottom: 25,
	},
	heading2: {
		color: theme.colors.secondary,
		textAlign: 'left',
		paddingLeft: '10%',
		paddingRight: '10%',
		paddingBottom: '7px',
		paddingTop: '20px',
		marginBottom: 25,
	},
	heading3: {
		color: theme.colors.tertiary,
		textAlign: 'left',
		paddingLeft: '10%',
		paddingRight: '10%',
		paddingBottom: '7px',
		paddingTop: '20px',
		marginBottom: 25,
	},
	heading4: {
		color: CNBlue,
		textAlign: 'left',
		paddingLeft: '10%',
		paddingRight: '10%',
		paddingBottom: '7px',
	},
	paragraph: {
		color: theme.colors.primary,
		textAlign: 'left',
		paddingLeft: '10%',
		paddingRight: '10%',
	},
	list: {
		marginTop: 10,
	},
	listItem: {
		marginBottom: 10,
	},
	sectionTitle: {
		color: theme.colors.secondary,
		textAlign: 'left',
		paddingLeft: '10%',
		paddingRight: '10%',
		fontSize: '18px',
		fontWeight: 'bold',
		marginBottom: 10,
	},
	pageContent: {
		color: theme.colors.secondary,
		textAlign: 'left',
		justifyContent: 'left',
	},
	table: {
		paddingLeft: '10%',
		paddingRight: '10%',
	},
	tableHeader: {
		color: theme.colors.secondary,
		textAlign: 'center',
	},
	tableRow: {
		color: theme.colors.secondary,
	},
	tableColumn: {
		color: theme.colors.secondary,
		paddingBottom: 20,
	},
	tableColumnSubHeading: {
		color: theme.colors.secondary,
		fontWeight: '800',
		marginTop: 15,
		marginBottom: 7,
	},
};

export default Content;



