import React, { Component } from "react";
import {Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {bindActionCreators} from "redux";
import {updateWindowWidth} from "../../reducers/page";
import {connect} from "react-redux";
import ContentTitle from "./ContentTitle";
import ContentLeft from "./ContentLeft";
import ContentImage from "./ContentImage";
import ContentScreenshotsTitle from "./ContentScreenshotsTitle";
import ContentScreenshotsSubTitle from "./ContentScreenshotsSubTitle";
import AppleAppStore from "../../Components/images/AppleAppStore";
import GooglePlayStore from "../../Components/images/GooglePlayStore";
import Image from "react-bootstrap/Image";
import merge from "lodash.merge";
import cloneDeep from "lodash.clonedeep";


class Section extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: props.style,
            ...props,
            ...{
                title: props.title,
                details: props.children,
                customerImage: props.customerImage,
                merchantImage: props.merchantImage,
                imageLocation: props.imageLocation,
                mobileWidthThreshold: props.mobileWidthThreshold,
                imageTitle: props.imageTitle,
                callToAction: props.callToAction,
                imageSide: props.imageSide,
            },
            ...{windowWidth: window.innerWidth},
        };
    }

    handleResize = (e) => {

        this.state.updateWindowWidth(window.innerWidth);
        this.setState(() => ({ windowWidth: window.innerWidth}));
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    renderSmallScreens() {
        const style = merge(cloneDeep(this.state.style), {overflowX: "hidden"});
        return (
            <Container
                fluid
                className="m-0 p-2"
                style={style}
            >
                <ContentTitle
                    alignment='center'
                    className="col-sm-12 col-md-12 pt-0"
                >
                    {this.state.title}
                </ContentTitle>

                {this.state.imageTitle
                    ? <ContentScreenshotsTitle>{this.state.imageTitle}</ContentScreenshotsTitle>
                    : <div></div>
                }
                {this.state.imageSubtitle1
                    ? <ContentScreenshotsSubTitle style={this.state.style}>{this.state.imageSubtitle1}</ContentScreenshotsSubTitle>
                    : <div></div>
                }
                {this.state.imageSubtitle2
                    ? <ContentScreenshotsSubTitle style={this.state.style}>{this.state.imageSubtitle2}</ContentScreenshotsSubTitle>
                    : <div></div>
                }
                {this.state.imageSubtitle3
                    ? <ContentScreenshotsSubTitle style={this.state.style}>{this.state.imageSubtitle3}</ContentScreenshotsSubTitle>
                    : <div></div>
                }

                <div className="d-flex justify-content-center">
                    <div>
                        <Image fluid src={this.state.merchantImage} />
                        <div className="my-0 py-0 pr-5">Merchant Facing</div>
                    </div>
                    <div className="my-0 py-0 px-3"> </div>
                    <div>
                        <Image fluid src={this.state.customerImage} />
                        <div className="my-0 py-0 pl-5">Customer Facing</div>
                    </div>
                </div>

                <div>{this.state.callToAction}</div>
                <div className="d-flex justify-content-center">
                    <div style={{paddingTop: 0}}><AppleAppStore/></div>
                    <div className="my-0 py-0 px-3"> </div>
                    <div style={{paddingTop: 0}}><GooglePlayStore/></div>
                </div>

                <div className="text-center" style={{paddingTop: 15}}>
                    {this.state.details}
                </div>

            </Container>
        );
    }

    renderMediumScreenTitleAndText() {
        const style = merge(cloneDeep(this.state.style), {overflowX: "hidden"});
        return (
            <Col >
                <ContentTitle
                    alignment='left'
                    className="pb-md-1"
                    style={style}
                >
                    {this.state.title}
                </ContentTitle>
                <ContentLeft>
                    {this.state.details}
                </ContentLeft>
                <div style={{paddingTop: 45}}>{this.state.callToAction}</div>
                <Row className="justify-content-center">
                    <Col></Col>
                    <Col style={{paddingTop: 10}}>
                        <AppleAppStore/>
                    </Col>
                    <Col style={{paddingTop: 10}}>
                        <GooglePlayStore/>
                    </Col>
                    <Col></Col>
                </Row>
            </Col>
        );
    }

    renderMediumScreenImage() {
        const style = merge(cloneDeep(this.state.style));
        return (
            <Col>
                {this.state.imageTitle
                    ? <ContentScreenshotsTitle>{this.state.imageTitle}</ContentScreenshotsTitle>
                    : <div></div>
                }
                {this.state.imageSubtitle1
                    ? <ContentScreenshotsSubTitle style={style}>{this.state.imageSubtitle1}</ContentScreenshotsSubTitle>
                    : <div></div>
                }
                {this.state.imageSubtitle2
                    ? <ContentScreenshotsSubTitle style={style}>{this.state.imageSubtitle2}</ContentScreenshotsSubTitle>
                    : <div></div>
                }
                {this.state.imageSubtitle3
                    ? <ContentScreenshotsSubTitle style={style}>{this.state.imageSubtitle3}</ContentScreenshotsSubTitle>
                    : <div></div>
                }

                <div className="d-flex justify-content-center pt-md-3">
                    <div>
                        <Image fluid src={this.state.merchantImage} />
                        <div className="my-0 py-0 pr-5">Merchant Facing</div>
                    </div>
                    <div className="my-0 py-0 px-3"> </div>
                    <div>
                        <Image fluid src={this.state.customerImage} />
                        <div className="my-0 py-0 pl-5">Customer Facing</div>
                    </div>
                </div>

            </Col>
        );
    }

    renderMediumScreens() {
        let leftContent, rightContent;
        if (this.state.imageSide === 'left') {
            leftContent = this.renderMediumScreenImage();
            rightContent = this.renderMediumScreenTitleAndText();
        } else {
            leftContent = this.renderMediumScreenTitleAndText();
            rightContent = this.renderMediumScreenImage();
        }
        const style = merge(cloneDeep(this.state.style), {overflowX: "hidden"});

        return (
            <Container
                fluid
                className="m-0 p-2"
                style={style}
            >
                <Row className="align-items-center">
                    {leftContent}
                    {rightContent}
                </Row>
            </Container>
        );
    }

    render() {
        if (this.state.windowWidth >= this.state.mobileWidthThreshold) {
            return this.renderMediumScreens();
        } else {
            return this.renderSmallScreens();
        }
    }
}


Section.defaultProps = {
    title: 'A Section Title',
    details: "Lorum ipsum and stuff",
    imageLocation: 'center',
    mobileWidthThreshold: 768,
    imageTitle: null,
    imageSubtitle1: null,
    imageSubtitle2: null,
    imageSubtitle3: null,
    callToAction: null,
};

const mapStateToProps = (state) => {
    const { page } = state;
    return { page };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        updateWindowWidth,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Section);

