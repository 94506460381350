import React, { Component } from "react";
import { SectionTitle, P } from "../Components/Content/Content";


class AppComingSoon extends Component {
	render() {
		return (
			<di>
				<section>
					<SectionTitle>Mobile App Coming Soon!</SectionTitle>
					<P>
						Our mobile app for both iOS and Android will be released soon so, check back here for more
						information.
					</P>
				</section>
			</di>
		);
	}
}


const styles = {};

export default AppComingSoon;

